<template>

<!--  修改学生信息-->
  <div class="page">
    <div class="school-form">
      <el-form label-width="168px" :model="ruleForm" :rules="rules" ref="ruleForm" size="mini" class="school-ruleForm el-form">
        <el-form-item label="所属学校" prop="schoolName">
          <el-input v-model="schoolName" disabled></el-input>
        </el-form-item>
        <el-form-item label="所属年级" prop="newGradeId">
          <el-input v-model="gradeName" disabled></el-input>
        </el-form-item>
        <el-form-item label="所属班级" prop="classId">
          <el-input v-model="className" disabled></el-input>
        </el-form-item>

        <el-form-item label="姓名" prop="name">
          <el-input maxlength="10" v-model="ruleForm.name" placeholder="请输入学生姓名"></el-input>
        </el-form-item>

        <el-form-item label="学号" prop="studentId">
          <el-input maxlength="11" v-model="ruleForm.studentId" placeholder="请输入学生学号"></el-input>
        </el-form-item>
<!--        性别-->
        <el-form-item label="性别" prop="sex">
          <el-radio-group v-model="ruleForm.sex">
            <el-radio label="男">男</el-radio>
            <el-radio label="女">女</el-radio>
          </el-radio-group>
        </el-form-item>
<!--        出生日期-->

          <el-form-item label="出生日期" prop="birthday">
            <el-date-picker
              class="input-class"
              v-model="ruleForm.birthday"
              type="date"
              placeholder="请选择生日"
              :picker-options="pickerOptions"
              value-format="timestamp"
            >
            </el-date-picker>
          </el-form-item>

        <el-form-item label="身份证号" prop="idCard">
        <el-input maxlength="18" v-model="ruleForm.idCard" placeholder="请输入学生的身份证号"></el-input>
        </el-form-item>

        <el-form-item label="手机号码" prop="phone">
          <el-input maxlength="11" v-model="ruleForm.phone" placeholder="请输入家长的手机号">
          </el-input>
        </el-form-item>
        <FormButtonGroup @saveData="submitForm('ruleForm')" save-text="保存"></FormButtonGroup>

      </el-form>
    </div>
  </div>
</template>

<script>
// import StudentsService from '@/globals/service/detection/Students.js'
import studentService from '@/globals/service/management/student.js'

export default {

  data () {
    return {
      pickerOptions: {
        disabledDate: (time) => {
          return time.getTime() > new Date().getTime()
        }
      },
      routerName: '',
      schoolName: '',
      gradeName: '',
      className: '',
      schoolOptions: [],
      gradeOptions: [],
      classOptions: [],
      pageSize: 100,
      startPage: 1,
      ruleForm: {
        school: '',
        newGradeId: null,
        classId: null,
        name: '',
        studentId: '',
        sex: '',
        birthday: '',
        idCard: '',
        phone: ''
      },
      rules: {
        name: [
          { required: true, message: '请输入学生姓名', trigger: 'change' }
        ],
        studentId: [
          { required: false, message: '请输入学生学号', trigger: 'blur' }
        ],
        sex: [
          { required: true, message: '请输入学生性别', trigger: 'blur' }
        ],
        birthday: [
          { required: true, message: '请选择日期', trigger: 'blur' }
        ],
        idCard: [
          { required: false, pattern: /^\d{15}|\d{}18$/, message: '请输入学生身份证号', trigger: 'blur' }
        ],
        phone: [
          { required: true, message: '请输入家长的手机号', trigger: 'blur' },
          {
            pattern: /^1[3456789]\d{9}$/,
            message: '目前只支持中国大陆的手机号码',
            trigger: 'blur'
          }
        ]
      }
    }
  },
  mounted () {
    const row = this.$route.query
    this.schoolName = row.schoolName
    this.gradeName = row.gradeName
    this.className = row.className
    this.ruleForm = {
      childrenId: row.id,
      school: row.schoolId !== '-' ? row.schoolId : '',
      newGradeId: row.gradeId !== '-' ? row.gradeId : '',
      classId: row.classId !== '-' ? row.classId : '',
      name: row.nickName !== '-' ? row.nickName : '',
      studentId: row.studentId !== '-' ? row.studentId : '',
      sex: row.sex !== '-' ? row.sex : '',
      birthday: row.birthday !== '-' ? row.birthday : '',
      idCard: row.idCard !== '-' ? row.idCard : '',
      phone: row.phone !== '-' ? row.phone : ''
    }
  },
  created () {
    this.routerName = this.$route.meta.title ? this.$route.meta.title : ''
  },
  methods: {
    goTo (path, query) {
      this.$router.push({ path: path, query: query })
    },
    submitForm (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          const that = this
          that.fullscreenLoading = true
          const ruleForm = this.ruleForm
          studentService.editStudent(ruleForm).then(res => {
            this.$message({
              message: res.errMsg,
              type: 'success',
              duration: 1000,
              onClose: function () {
                that.$router.go(-1)
                that.fullscreenLoading = false
              }
            })
          }).catch(err => {
            if (err.errMsg) {
              this.$message.error(err.errMsg)
            } else {
              this.$notify.error({ title: '错误', message: err.error })
            }
            that.fullscreenLoading = false
          })
        } else {
          this.$message.error('请填入相应信息！')
          return false
        }
      })
    },
    returnPage () {
      if (window.history.length <= 1) {
        this.$router.push({ path: '/system/storageManagement' })
        return false
      } else {
        this.$router.go(-1)
      }
    }
  }

}
</script>
<style lang="scss" scoped>
.school-form {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 24px 0;
  box-sizing: border-box;
  max-width: 500px;
  .input-class{
    width: 175px;
  }
}
.header-container{
  width: 100%;
  height: 43px;
  border-bottom: 1px solid #f5f5f5;
  .header-section{
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: space-between;
    .route-name{
      line-height: 43px;
      font-size: 12px;
      color: #51B25F;
    }
    .button-section{
      display: flex;
      align-items: center;
    }
  }
}
.page{
    padding: 20px 10px;
    background-color: #fff;
    min-height: 100%;
  }
  .red{
    color: #f50e0e;
  }
</style>
